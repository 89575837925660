import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import ApplicationEditorPage from "./app/editor.page";
import ChannelIndexPage from "./channel/index.page";
import NotFoundPage from "./common/404.page";
import HomePage from "./common/home.page";
import LoginPage from "./common/login.page";
import ProtectedRoute from "./common/protected.route";
import ConfigurationIndexPage from "./configure/index.page";
import { AuthProvider } from "./contexts/auth.context";
import { TelemetryProvider } from "./contexts/tacker.context";
import PersonalIndexPage from "./personal/index.page";
import { GQLClientProvider } from "./helpers/gqlClientHelper";
import HelpPage from "./common/help.page";
import TourPage from "./common/tour.page";
import { NotificationsProvider } from "./contexts/notifications.context";
import InvitePage from "./common/invite.page";
import DiagramGeneratePage from "./common/generate.page";
import { fetcher } from "./helpers/utils";
import { SWRConfig } from "swr";
import CreateDiagramPage from "./common/openai/create.page";
import UpdateDiagramPage from "./common/openai/update.page";
import RequireContextPage from "./common/openai/require.page";
import { RegionalAppProvider } from "./contexts/regional.context";
import { checkRegionByEmail } from "./helpers/authHelper";
import DiagramCreatePage from "./common/create.page";

const resource = checkRegionByEmail(process.env.REACT_APP_GRAVITY_HOST);

function AppInitializer({ appTheme }) {
  const region = resource.read();

  return (
    <RegionalAppProvider region={region}>
      <AuthProvider>
        <TelemetryProvider>
          <SWRConfig
            value={{
              shouldRetryOnError: false,
              revalidateOnFocus: false,
              fetcher,
            }}
          >
            <NotificationsProvider
              theme={["dark", "contrast"].includes(appTheme) ? "dark" : "light"}
            >
              <GQLClientProvider>
                <Router>
                  <Switch>
                    <Route exact path="/" component={HomePage} />
                    <Route exact path="/login" component={LoginPage} />
                    <Route exact path="/help" component={HelpPage} />
                    <Route exact path="/tour" component={TourPage} />
                    <ProtectedRoute
                      path="/personal"
                      component={PersonalIndexPage}
                    />
                    <ProtectedRoute
                      path="/channel/:folderId"
                      component={ChannelIndexPage}
                    />
                    <ProtectedRoute
                      path="/configure"
                      component={ConfigurationIndexPage}
                    />
                    <ProtectedRoute
                      path="/app/:workspaceId"
                      component={ApplicationEditorPage}
                    />
                    <ProtectedRoute
                      path="/invite/:workspaceId"
                      component={InvitePage}
                    />
                    <ProtectedRoute
                      path="/generate"
                      component={DiagramGeneratePage}
                    />
                    <ProtectedRoute
                      path="/create"
                      component={DiagramCreatePage}
                    />
                    <ProtectedRoute
                      path="/create-diagram-ai"
                      component={CreateDiagramPage}
                    />
                    <ProtectedRoute
                      path="/update-diagram-ai"
                      component={UpdateDiagramPage}
                    />
                    <ProtectedRoute
                      path="/require-context-ai"
                      component={RequireContextPage}
                    />
                    <Route path="*" component={NotFoundPage} />
                  </Switch>
                </Router>
              </GQLClientProvider>
            </NotificationsProvider>
          </SWRConfig>
        </TelemetryProvider>
      </AuthProvider>
    </RegionalAppProvider>
  );
}

export default AppInitializer;
