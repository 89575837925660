import WorkspaceThumbnail from "./WorkspaceThumbnail";

export default function RightSidebar({ workspace }) {
  return (
    <>
      <div className="relative px-3 py-6 bg-creately-primary-dark flex items-center justify-between rounded-md mb-2">
        <h3 className="flex-grow text-sm text-creately-neutrals-xlight max-w-[165px]">
          Click to share workspace to stage and collaborate
        </h3>
        <div className="absolute right-14 bottom-[8px]">
          <img
            src="/images/meeting-arrow-pointer.png"
            alt="Click on 'Share to Meeting' icon"
          />
        </div>
        <div>
          <img src="/images/share-to-meeting-icon.png" alt="Share to Stage" />
        </div>
      </div>
      <WorkspaceThumbnail workspace={workspace} />
    </>
  );
}
