import { checkIfInTeams } from "../hooks/useTeams";
import wrapPromise from "./wrapPromise";
import { app } from "@microsoft/teams-js";

export const getUser = (gqlUserResponse) => {
  const {
    data: { user: userInfo },
  } = gqlUserResponse;
  return {
    id: userInfo.id,
    name: `${userInfo.firstName} ${userInfo.lastName}`,
    email: userInfo.email,
    image: userInfo.avatar?.filename || null,
    organization: userInfo.organization,
  };
};

export const checkEligibility = (gravityUrl, email) => {
  return new Promise(async (resolve, reject) => {
    const tokenResponse = await fetch(`${gravityUrl}/v1/basic/has-team-plan`, {
      method: "POST",
      body: JSON.stringify({ email }),
      headers: { "Content-Type": "application/json" },
    });
    const teamPlanCheck = await tokenResponse.json();
    if (!tokenResponse.ok) {
      reject({
        errorCode: tokenResponse.status,
        message: "Unable to check eligibility",
      });
    }
    if (!teamPlanCheck.isTeamUser) {
      reject({
        errorCode: 409,
        message:
          "You need to have a Team plan to use Creately inside Microsoft Teams",
      });
      return;
    }
    resolve(teamPlanCheck);
  });
};

export const checkIfRegistered = async function (
  gravityUrl,
  email,
  tenantId,
  region
) {
  const userExistenceResponse = await fetch(
    `${gravityUrl}/v1/basic/is-user-registered`,
    {
      method: "POST",
      body: JSON.stringify({ email, msTenant: tenantId }),
      headers: { "Content-Type": "application/json" },
    }
  );
  const existenceResponse = await userExistenceResponse.json();
  if (!userExistenceResponse.ok) {
    const err = new Error("Unable to check if user is registered");
    err.errorCode = userExistenceResponse.status;
  }
  if (existenceResponse.status === "USER_EXIST") {
    return {
      isExistingUser: true,
      userRegion: existenceResponse.region,
      integrations: existenceResponse.integrations,
    };
  } else {
    // we can safely assume if user is not registered, there are no integrations
    return {
      isExistingUser: false,
      userRegion: region,
      integrations: existenceResponse.integrations,
    };
  }
};

export const authenticate = (gravityUrl, tenantId, email, teamsToken) => {
  async function authPromise() {
    const tokenResponse = await fetch(
      `${gravityUrl}/v1/provider/msteams/authenticate`,
      {
        method: "POST",
        body: JSON.stringify({ tenantId, teamsToken }),
        headers: { "Content-Type": "application/json" },
      }
    );
    const authToken = await tokenResponse.json();
    if (!tokenResponse.ok) {
      const err = new Error("Failed to authenticate user");
      err.errorCode = tokenResponse.status;
      throw err;
    }
    if (authToken.status === 214) {
      const err = new Error(
        "A valid work/school Microsoft Account is required to signup. Make sure you have updated correct email in your Azure contact details"
      );
      err.errorCode = 401;
      throw err;
    } else if (authToken.status === 250) {
      const err = new Error(
        "Your account is disabled. Please contact Creately Support to resolve this"
      );
      err.errorCode = 400;
      throw err;
    } else if ([100, 207, 215, 216, 263, 264].includes(authToken.status)) {
      const err = new Error(
        "We are unable authenticate your email. Make sure you have a valid work/school Microsoft Account. Plese contact Creately Support if issue continues"
      );
      err.errorCode = 500;
      throw err;
    }
    return authToken;
  }

  return checkIfRegistered(gravityUrl, email).then((registerStatus) => {
    if (registerStatus.isExistingUser) {
      return checkEligibility(gravityUrl, email).then(() => authPromise());
    }
    return authPromise();
  });
};

export const fetchUser = async (apiUrl, gravityToken) => {
  const userInfo = await fetch(apiUrl, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      "creately-gravity-token": gravityToken,
    },
    body: JSON.stringify({
      query: `{
          user {
            id
            firstName
            lastName
            avatar {filename}
            email
            organization {
              id
              name
              planName
            }
          }
        }`,
    }),
  })
    .then((res) => res.json())
    .catch((err) => console.log("WHATTT" + err));
  return { ...getUser(userInfo), gravityToken };
};

export const checkRegionByEmail = function (gravityUrl) {
  const isInsideTeams = checkIfInTeams();
  if (!isInsideTeams && process.env.NODE_ENV === "development") {
    return wrapPromise(
      new Promise((res) =>
        res(process.env.NODE_ENV === "development" ? "dev" : "us")
      )
    );
  }
  const promise = app.initialize().then(() => {
    return app.getContext().then((context) => {
      const { user: teamsUser } = context;
      return fetch(`${gravityUrl}/v1/basic/is-user-registered`, {
        method: "POST",
        body: JSON.stringify({
          email: teamsUser.userPrincipalName,
          msTenant: teamsUser.tenant?.id,
        }),
        headers: { "Content-Type": "application/json" },
      })
        .then((res) => res.json())
        .then((res) => res.region || "us");
    });
  });

  return wrapPromise(promise);
};
