import Layout from "../common/app.layout";
import { useTeams } from "../hooks/useTeams";
import CreatelyEditorFrame from "../components/CreatelyEditorFrame";
import useRouteQuery from "../hooks/useRouteQuery";
import Spinner from "../components/Spinner";

function DiagramCreatePage() {
  const query = useRouteQuery();
  const [{ isInitializing }] = useTeams();

  if (isInitializing) {
    return (
      <div className="flex items-center justify-center w-screen h-screen">
        <Spinner text="Loading Creately Editor" />
      </div>
    );
  }

  return (
    <Layout>
      <CreatelyEditorFrame
        allowEdit={true}
        templateId={query.get("templateId")}
      />
    </Layout>
  );
}

export default DiagramCreatePage;
