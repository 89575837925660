import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import classnames from "classnames";
import { useAuth } from "../contexts/auth.context";
import { useLocation, useHistory } from "react-router-dom";
import { ChevronLeftIcon } from "@heroicons/react/24/outline";
import { useTeams } from "../hooks/useTeams";
import {
  REGIONS,
  backGIFPopup,
  getDiagramType,
  getRegionalURLs,
  gotoDeepLink,
  shareGIFPopup,
} from "../helpers/utils";
import { useTelemetry } from "../contexts/tacker.context";
import {
  getTeamsWindowContext,
  TEAMS_WINDOW_CONTEXT,
} from "../helpers/teamsHelper";
import * as microsoftTeams from "@microsoft/teams-js";
import PopupModal from "./PopupModal";
import { toast } from "react-toastify";
import Spinner from "./Spinner";
import useQueryParams from "../hooks/useQueryParams";
import { useRegionalApp } from "../contexts/regional.context";
import AppSwitcher from "./AppSwitcher";

export default function CreatelyEditorFrame({
  workspace,
  allowEdit = false,
  contextId = undefined,
  templateId = undefined,
  containerId = undefined,
  appScope = undefined,
  folderId = undefined,
  onLogout = () => {},
}) {
  const [appInitializing, setAppInitializing] = useState(true);
  const [showNav, setShowNav] = useState(false);
  // const [showAI, setShowAI] = useState(false);
  const [showPopup, setShowPopup] = useState(false);
  const { user } = useAuth();
  const iFrameRef = useRef(null);
  const authIFrameRef = useRef(null);
  const history = useHistory();
  const { state } = useLocation();
  const [{ teamsContext }] = useTeams();
  const { env: regionalEnv, region } = useRegionalApp();
  const [foreignRegion, setForeignRegion] = useState(() => {
    const defaultRegion = REGIONS.find((r) => r.code === region);
    return defaultRegion || REGIONS[0];
  });
  const appHost = useMemo(() => {
    if (foreignRegion.code !== region) {
      const foreignEnv = getRegionalURLs(foreignRegion.code);
      return process.env.NODE_ENV === "development"
        ? "http://localhost:4200"
        : foreignEnv.APP_HOST;
    }
    return process.env.NODE_ENV === "development"
      ? "http://localhost:4200"
      : regionalEnv.APP_HOST;
  }, [region, regionalEnv, foreignRegion]);
  const diagramUrl = useMemo(() => {
    if (foreignRegion.code !== region || appScope) {
      return "/d/start/dashboard";
    }
    if (allowEdit) {
      if (contextId && templateId) {
        if (["web", "desktop"].includes(teamsContext.app?.host?.clientType)) {
          return `/d/create?templateId=${templateId}&context=${contextId}&container=${containerId}&diagramType=${getDiagramType(
            containerId
          )}`;
        }
        return `/d/${templateId}/view`;
      } else if (templateId) {
        if (["web", "desktop"].includes(teamsContext.app?.host?.clientType)) {
          return `/d/create?templateId=${templateId}`;
        }
        return `/d/${templateId}/view`;
      }
      return `/d/${workspace.id}/edit`;
    }
    return `/d/${workspace.id}/view`;
  }, [
    allowEdit,
    workspace,
    contextId,
    templateId,
    containerId,
    region,
    foreignRegion,
    appScope,
    teamsContext,
  ]);
  const { telemetry } = useTelemetry();
  const trackAppLoad = useCallback(() => {
    const appContext = getTeamsWindowContext(teamsContext);
    if (appContext === TEAMS_WINDOW_CONTEXT.tab.personal) {
      telemetry.track("tab.personal.load");
    }
    if (appContext === TEAMS_WINDOW_CONTEXT.tab.channel) {
      telemetry.track("tab.channel.load");
    }
    if (appContext === TEAMS_WINDOW_CONTEXT.meeting.stage) {
      telemetry.track("meeting.stage.load");
    }
  }, [telemetry, teamsContext]);
  const appContext = getTeamsWindowContext(teamsContext);
  const isPersonalTab = appContext === TEAMS_WINDOW_CONTEXT.tab.personal;
  const [showAppLoader, setShowAppLoader] = useState(true);
  const query = useQueryParams();

  const updateCard = useCallback(
    (workspaceId) => {
      return fetch(
        `${
          process.env.REACT_APP_CREATELY_AI_SVC
        }/api/oai/close-context/${query.get("context")}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            messageId: query.get("messageId"),
            conversationPayload: query.get("ref"),
            metadata: query.get("metadata"),
            workspaceId,
          }),
        }
      );
    },
    [query]
  );

  const handleMessage = useCallback(handleWindowMessage, [
    user,
    state,
    trackAppLoad,
    isPersonalTab,
    allowEdit,
    appInitializing,
    teamsContext,
    containerId,
    contextId,
    templateId,
    updateCard,
    appHost,
    appScope,
    folderId,
    onLogout,
    appContext,
  ]);

  const addToPrivateGroup = useCallback(addUserToPrivateGroup, [
    user,
    teamsContext,
  ]);

  const addToOrgGroup = useCallback(addUserToOrgGroup, [user, teamsContext]);

  async function addUserToPrivateGroup() {
    return await fetch(
      `${process.env.REACT_APP_CREATELY_AI_SVC}/api/metadata/group/member`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: user.gravityToken,
        },
        body: JSON.stringify({
          channelId: teamsContext.channel.id,
          tenantId: teamsContext.user.tenant.id,
          groupName: teamsContext.channel.displayName,
          ownerGroupId: teamsContext.channel.ownerGroupId,
          uid: user.id,
          planId: user.organization.id,
        }),
      }
    )
      .then((res) => res.json())
      .then((group) => group.groupId);
  }

  async function addUserToOrgGroup() {
    return await fetch(
      `${process.env.REACT_APP_CREATELY_AI_SVC}/api/metadata/group/member`,
      {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: user.gravityToken,
        },
        body: JSON.stringify({
          channelId: teamsContext.team.internalId,
          tenantId: teamsContext.user.tenant.id,
          uid: user.id,
          planId: user.organization.id,
        }),
      }
    )
      .then((res) => res.json())
      .then((group) => group.groupId);
  }

  useEffect(() => {
    if (appInitializing) return;
    // auto-create groups only in channel scope
    if (user && teamsContext && teamsContext.channel) {
      if (
        teamsContext.channel.membershipType === "Regular" &&
        teamsContext.team.groupId
      ) {
        // add user to existing group [created during bot 'installationUpdate']
        addToOrgGroup(); //.then((groupId) => updateGroupId(groupId));
      } else if (
        teamsContext.channel.membershipType === "Private" &&
        teamsContext.team.groupId === undefined
      ) {
        // create a private group + add user
        addToPrivateGroup(); //.then((groupId) => updateGroupId(groupId));
      }
    }
  }, [appInitializing, teamsContext, user, addToOrgGroup, addToPrivateGroup]);

  useEffect(() => {
    window.addEventListener("message", handleMessage);
    return () => {
      window.removeEventListener("message", handleMessage);
    };
  }, [appInitializing, handleMessage]);

  function sendMessage(payload) {
    iFrameRef.current.contentWindow.postMessage(JSON.stringify(payload), "*");
  }

  function sendAuthMessage(payload) {
    authIFrameRef.current.contentWindow.postMessage(
      JSON.stringify(payload),
      "*"
    );
  }

  function handleWindowMessage(messageData) {
    if (messageData.origin === appHost) {
      try {
        if (messageData.data.startsWith("!_")) return;
        const eventData = JSON.parse(messageData.data);
        if (
          eventData.source === "creately" &&
          eventData.event === "document:load"
        ) {
          if (appInitializing) {
            // send auth message during initialization [will set cookie for the domain first]
            sendAuthMessage({
              event: "user:setToken",
              data: {
                token: user.gravityToken,
              },
            });
          } else {
            sendMessage({
              event: "app:enablePluginOpts",
              data: {
                pluginApp: "msteams",
                templateSelect: false,
                clientType: teamsContext.app?.host?.clientType,
              },
            });
            setShowAppLoader(false);
          }
        }
        if (
          eventData.source === "creately" &&
          eventData.event === "document:init"
        ) {
          setAppInitializing(false);
        }
        if (
          eventData.source === "creately" &&
          eventData.event === "document:close"
        ) {
          console.log("APP CLOSE EVENT --- RECEIVED");
        }
        if (
          eventData.source === "creately" &&
          eventData.event === "document:visible"
        ) {
          setShowNav(true);
          if (appScope) {
            sendMessage({
              event: "app:interfaceScope",
              data: {
                embedScope: appScope,
              },
            });
            if (folderId) {
              sendMessage({
                event: "app:view",
                data: { folderId, screen: "folder" },
              });
            }
          }
          if (allowEdit) {
            sendMessage({
              event: "app:enableLeftSidebar",
              data: {
                // should match nucleus' panel IDs
                showPanels: ["folders", "task", "edata"],
                selectPanel: "task",
              },
            });
          }
          if (state && state.starter) {
            sendMessage({
              event: "use:template",
              data: {
                templateId: "XYZ",
              },
            });
          }
          if (state && state.starter) {
            sendMessage({
              event: "use:context",
              data: {
                contextId,
                templateId,
                containerId,
              },
            });
          }
          trackAppLoad();
        }
        if (
          eventData.source === "creately" &&
          eventData.event === "template:new"
        ) {
          console.log("DATA --- ", eventData);
          updateCard(eventData.data.newDocumentId);
        }
        if (
          eventData.source === "creately" &&
          eventData.event === "feature:upgrade"
        ) {
          microsoftTeams.monetization.openPurchaseExperience((e) => {
            console.log("purchase experience callback is being called");
            console.log(e);
            if (!!e && typeof e !== "string") {
              console.log(JSON.stringify(e));
            }
            return;
          });
        }
        if (
          eventData.source === "creately" &&
          eventData.event === "navigate:back"
        ) {
          sendMessage({
            event: "app:interfaceScope",
            data: {
              embedScope:
                appContext === TEAMS_WINDOW_CONTEXT.tab.personal
                  ? "personal"
                  : "channel",
            },
          });
          sendMessage({
            event: "app:navigate",
            data: { path: "/start/dashboard" },
          });
        }
        if (
          eventData.source === "creately" &&
          eventData.event === "navigate:logout"
        ) {
          onLogout();
        }
        if (
          isPersonalTab &&
          eventData.source === "creately" &&
          eventData.event === "document:share"
        ) {
          triggerShareToast();
        }
      } catch (err) {
        console.log("ERROR POST --- ", messageData);
        console.error("ERROR --- ", err);
      }
    }
  }

  function trackAppExit() {
    const appContext = getTeamsWindowContext(teamsContext);
    if (appContext === TEAMS_WINDOW_CONTEXT.tab.personal) {
      telemetry.track("tab.personal.quit");
    }
    if (appContext === TEAMS_WINDOW_CONTEXT.tab.channel) {
      telemetry.track("tab.channel.quit");
    }
    if (appContext === TEAMS_WINDOW_CONTEXT.meeting.stage) {
      telemetry.track("meeting.stage.quit");
    }
  }

  function navigateBack() {
    // Show popup here - set a variable and then show component based on that?
    // Clicks from that would carry out the following code.
    trackAppExit();
    console.log("[TEAMS CONTEXT] --- ", teamsContext);
    if (!teamsContext?.page?.subPageId) {
      history.push(state.dashboardPath);
    } else {
      gotoDeepLink({ subEntityId: "" });
    }
  }

  function triggerShareToast() {
    const randIndex = Math.floor(Math.random() * (shareGIFPopup.length - 1));
    const img = shareGIFPopup[randIndex];
    return toast(
      <div className="hidden sm:block">
        <h4 className="font-semibold text-creately-neutrals-xxdark dark:text-creately-neutrals-xlight dark:text-opacity-70 mb-2">
          Share workspaces in a meeting
        </h4>
        <img src={img.image} alt="Sharing in Meetings" />
      </div>,
      {
        position: toast.POSITION.BOTTOM_RIGHT,
        autoClose: false,
        closeButton: `<button><XMarkIcon className="h-6 w-6" /></button>`,
      }
    );
  }

  return (
    <>
      {isPersonalTab && (
        <PopupModal
          className="hidden sm:block"
          title="Begin collaborating with your colleagues on a Teams Channel"
          buttonText1="Go to Dashboard"
          buttonClick1={() => navigateBack()}
          buttonText2="Stay on Workspace"
          buttonClick2={() => setShowPopup(false)}
          visible={showPopup}
          carouselItems={backGIFPopup}
          onBlur={() => setShowPopup(false)}
        />
      )}
      {!appInitializing && showAppLoader && (
        <div className="flex items-center justify-center w-screen h-screen">
          <Spinner text="Loading your workspace. Please wait...!" />
        </div>
      )}
      <div className="h-screen">
        <div className="fixed left-0 top-4 transform z-10">
          {state && showNav && (
            <>
              <div className="group relative">
                <span className="bg-zinc-700 absolute hidden group-hover:inline-block text-white text-xs rounded py-1 px-4 top-1/2 left-full translate-x-2 -translate-y-1/2">
                  Back
                </span>
                <button
                  className="flex items-center text-blue-800 hover:bg-blue-200 hover:border-blue-400 border text-sm font-bold hover:underline hover:bg-opacity-95 duration-150 transition-colors p-1 ml-2"
                  onClick={() => {
                    if (isPersonalTab) {
                      setShowPopup(true);
                      return;
                    }
                    navigateBack();
                  }}
                >
                  <ChevronLeftIcon className="w-4 h-4" />
                </button>
              </div>
              <div className="group relative">
                <span className="bg-zinc-700 absolute hidden group-hover:inline-block text-white text-xs rounded py-1 px-4 top-1/2 left-full translate-x-2 -translate-y-1/2">
                  Switch Regions
                </span>
                <AppSwitcher
                  region={foreignRegion}
                  onChange={setForeignRegion}
                />
              </div>
            </>
          )}
        </div>
        {appInitializing && user && (
          <iframe
            ref={authIFrameRef}
            className="w-full h-full hidden"
            src={`${appHost}${diagramUrl}`}
            frameBorder="0"
            title="Creately Editor"
          ></iframe>
        )}
        {!appInitializing && user && (
          <iframe
            ref={iFrameRef}
            className={classnames({
              "w-full h-full": true,
              hidden: appInitializing,
            })}
            src={`${appHost}${diagramUrl}`}
            frameBorder="0"
            title="Creately Editor"
          ></iframe>
        )}
      </div>
    </>
  );
}
