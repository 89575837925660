import { Redirect } from "react-router-dom";
import Layout from "../common/app.layout";
import { useTeams } from "../hooks/useTeams";
import CreatelyEditorFrame from "../components/CreatelyEditorFrame";
import { useAuth } from "../contexts/auth.context";

function PersonalIndexPage() {
  const [{ teamsContext }] = useTeams();
  const { logout } = useAuth();

  if (teamsContext && teamsContext?.page?.subPageId) {
    return (
      <Redirect
        to={{
          pathname: `/app/${teamsContext.page.subPageId}`,
          state: { dashboardPath: "/personal" },
        }}
      />
    );
  }

  return (
    <Layout>
      <CreatelyEditorFrame
        workspace="dashboard"
        allowEdit={["web", "desktop"].includes(
          teamsContext.app?.host?.clientType
        )}
        appScope="personal"
        onLogout={logout}
      />
    </Layout>
  );
}

export default PersonalIndexPage;
