import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import Cookies from "js-cookie";
import { authenticate, fetchUser } from "../helpers/authHelper";
import { unstable_batchedUpdates as batchedUpdates } from "react-dom";
import Token from "../helpers/tokenDecode";
import { useTeams } from "../hooks/useTeams";
import { useRegionalApp } from "./regional.context";

export const fakeUser = {
  id: "1337",
  name: "John Doe",
  email: "john@email.com",
  image: "https://randomuser.me/api/portraits/men/68.jpg",
  gravityToken:
    "eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzUxMiJ9.eyJpc3MiOiJjcmVhdGVseS5jb20iLCJpYXQiOjE2NDQzOTE1MDEsImV4cCI6MTY0NDQ3NzkwMSwidWlkIjoiOUFGNTBFNDEtQjc2OS04RDA2LTc2MTItQzQyNDkxREQ1MEE1Iiwic3RhdHVzIjoiQUNUSVZFIiwicGxhbiI6ImZyZWUtMjAyMDA5MDQtYWxsLTUifQ.qPxHJqngnertP0HG_Oea7GiKmcKpLyL4WNQaNmoU3NvrmexwCoUnoJlYeILHXH7sQb5nyQhMjR81PFMdnePtgKtrbdiXx2OZtImEkh3rh3AZQhJN813HS28gJuH9V8tygrvfyRS17XZGRqEQyQI1HNjb9tZQML4fAcFoavu7lujKiUCYSKdV9RJMlq4rKsLemaLmQYE0ePn5vnUWv0hFHjyS-kCVjrYtjRIjJ9JUEGCM6jZV7tpFHIuKc_YVJYBKvJOslAV4ZZWS_o1CAJAjwdSg2JSOYmitEg2nCNAtu64ZuslHWhzRjIkCCAPkIs-nmHhmoG2cArtM838KQx_gl6w2vcd1ezb0MyFPBcT59oHF746NYRES5g_vYZfAv_fVZvBB6Z1bstN21aSY5bsEV4Le29UtPgFq93uoqydfTah4hrjHd5otNLFF0SK1JGB-jAwwql7AAJWN3X3jGWzYFMLrNctPgfXQEWjNY7jFN2NtILDBN3ER-jCc7_s8DyqWpuUxOSsoh6PsONkQ2dh6whKOQ1XJJlsqjoWIjP8naSBov1q4eA3XrsbvlOetBzWSg4ZSfEXx5JtbK0ZWzGnkI1P_ENAUDDBCopM6JdYT8ER4ZJcRwt42xd9LwE2sdV8x1DY-QsBOvW8szsnII9MZYeIN6AF8oZpD3uC-waD5vT0",
};

const AuthContext = createContext({});

export const TOKEN_COOKIE_NAME = "creately.auth.token";
export const USER_COOKIE_NAME = "creately.auth.user";

export const CURRENT_USER_ID_KEY = "app.current.user";
export const ACTIVE_USER_ID_KEY = "app.active.user";

const AuthProvider = (props) => {
  // const tokenCookie = Cookies.get(TOKEN_COOKIE_NAME);
  // const userCookie = Cookies.get(USER_COOKIE_NAME);

  const [{ teamsContext, isInitializing }] = useTeams();
  const [loggedIn, setLoggedIn] = useState(false);
  const [user, setUser] = useState(null);
  const [tokenCookie, setTokenCookie] = useState(
    Cookies.get(TOKEN_COOKIE_NAME)
  );
  const isTokenValid = useMemo(
    () => (tokenCookie ? new Token(tokenCookie).isValid() : false),
    [tokenCookie]
  );
  const { env: regionalEnv } = useRegionalApp();

  const isSameUser = () => {
    return (
      localStorage.getItem(CURRENT_USER_ID_KEY) ===
      localStorage.getItem(ACTIVE_USER_ID_KEY)
    );
  };

  const login = async (tenantId, email, teamsToken) => {
    const appToken = await authenticate(
      regionalEnv.AUTH_HOST,
      tenantId,
      email,
      teamsToken
    );
    const user = await fetchUser(regionalEnv.API_HOST, appToken.token);
    Cookies.set(TOKEN_COOKIE_NAME, appToken.token, {
      expires: 1,
      secure: true,
      sameSite: "none",
    });
    Cookies.set(USER_COOKIE_NAME, JSON.stringify(user), {
      expires: 1,
      secure: true,
      sameSite: "none",
    });
    localStorage.setItem(CURRENT_USER_ID_KEY, teamsContext.user.id);
    localStorage.setItem(ACTIVE_USER_ID_KEY, teamsContext.user.id);
    batchedUpdates(() => {
      setLoggedIn(true);
      setUser(user);
      setTokenCookie(appToken.token);
    });
    return user;
  };

  const loginWithToken = useCallback(
    async (gravityToken) => {
      try {
        const user = await fetchUser(regionalEnv.API_HOST, gravityToken);
        batchedUpdates(() => {
          setLoggedIn(true);
          setUser(user);
          setTokenCookie(gravityToken);
        });
      } catch (error) {
        console.error(error);
      }
    },
    [regionalEnv, setLoggedIn, setUser, setTokenCookie]
  );

  const logout = useCallback(
    (cb) => {
      Cookies.remove(TOKEN_COOKIE_NAME, {
        secure: true,
        sameSite: "none",
      });
      Cookies.remove(USER_COOKIE_NAME, {
        secure: true,
        sameSite: "none",
      });
      batchedUpdates(() => {
        setLoggedIn(false);
        setUser(null);
        setTokenCookie("");
        cb && cb();
      });
    },
    [setLoggedIn, setUser, setTokenCookie]
  );

  useEffect(() => {
    if (isInitializing || (user && loggedIn)) return;

    if (!isSameUser()) {
      logout();
      return;
    }

    if (isTokenValid && !user) {
      loginWithToken(tokenCookie);
    }
  }, [
    user,
    loggedIn,
    tokenCookie,
    isTokenValid,
    logout,
    loginWithToken,
    isInitializing,
  ]);

  return (
    <AuthContext.Provider
      value={{
        isTokenValid,
        tokenCookie,
        login,
        logout,
        loggedIn,
        user,
        loginWithToken,
        isSameUser,
      }}
      {...props}
    />
  );
};

const useAuth = () => useContext(AuthContext);

export { AuthProvider, useAuth };
