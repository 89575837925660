import Layout from "../common/app.layout";
import Spinner from "../components/Spinner";
import CreatelyEditorFrame from "../components/CreatelyEditorFrame";
import useSWR from "swr";
import useRouteQuery from "../hooks/useRouteQuery";

function DiagramGeneratePage() {
  const query = useRouteQuery();
  const { data, isLoading } = useSWR(
    `${
      process.env.REACT_APP_CREATELY_AI_SVC
    }/api/oai/generate-context/${query.get("context")}`
  );

  if (isLoading) {
    return (
      <div className="flex items-center justify-center w-screen h-screen">
        <Spinner text="Loading Creately Editor" />
      </div>
    );
  }

  return (
    <Layout>
      <CreatelyEditorFrame
        workspace={data}
        allowEdit={true}
        templateId={query.get("templateId")}
        contextId={query.get("context")}
        containerId={query.get("container")}
      />
    </Layout>
  );
}

export default DiagramGeneratePage;
