import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import * as microsoftTeams from "@microsoft/teams-js";
import { useAuth } from "../contexts/auth.context";
import { useTeams } from "../hooks/useTeams";
import {
  getClientToken,
  getTeamsWindowContext,
  requestConsent,
  TEAMS_WINDOW_CONTEXT,
} from "../helpers/teamsHelper";
import Layout from "./app.layout";
import { useTelemetry } from "../contexts/tacker.context";
import MessageModal from "../components/MessageModal";
import { useMutation } from "@apollo/client";
import { CREATE_WORKSPACE_COPY } from "../queries/personal";
import { useHistory } from "react-router-dom";
import { checkIfRegistered } from "../helpers/authHelper";
import { useNotifications } from "../contexts/notifications.context";
import ErrorNotification from "../components/ErrorNotification";
import { useRegionalApp } from "../contexts/regional.context";
import RegionPicker from "../components/RegionPicker";

const defaultTemplateId = process.env.REACT_APP_TUTORIAL_WORKSPACE_ID;
const defaultProjectId = "home";
const defaultWorkspaceName = "Welcome to Creately for Microsoft Teams";

export default function LoginPage() {
  const { state } = useLocation();
  const { login, loggedIn } = useAuth();
  const [{ teamsContext, isInitializing }] = useTeams();
  const [showSpinner, setShowSpinner] = useState(false);
  const { telemetry } = useTelemetry();
  const [isOpen, setIsOpen] = useState(false);
  const { show } = useNotifications();
  const { env: regionalEnv, region, changeRegion } = useRegionalApp();

  const [userState, setUserState] = useState({
    type: "none",
    msTeamsEnabled: false,
  });
  const tabContext = getTeamsWindowContext(teamsContext);
  const history = useHistory();
  const [documentCopy] = useMutation(CREATE_WORKSPACE_COPY);

  useEffect(() => {
    if (isInitializing || loggedIn) return;
    const { user: teamsUser } = teamsContext;
    telemetry.identify(teamsUser.id, {});
    telemetry.track("app.load", {
      value1Type: "Visit",
      value1: teamsUser.tenant.id,
      value2Type: "Target",
      value2: teamsUser.userPrincipalName,
    });
    checkIfRegistered(
      regionalEnv.AUTH_HOST,
      teamsUser.userPrincipalName,
      teamsUser.tenant?.id,
      region
    ).then((reg) => {
      setUserState({
        type: reg.isExistingUser ? "existing" : "first-time",
        msTeamsEnabled: reg.integrations?.microsoftTeams,
      });
      if (reg.isExistingUser) {
        changeRegion(reg.userRegion);
      } else if (!reg.isExistingUser && !reg.integrations?.microsoftTeams) {
        // new user, no tenant has setup in ANY region - let user pick region once)
        changeRegion(region);
      } else if (!reg.isExistingUser && reg.integrations?.microsoftTeams) {
        // switch to already setup tenant's region
        changeRegion(reg.integrations?.microsoftTeams?.region);
      }
    });
  }, [
    telemetry,
    teamsContext,
    isInitializing,
    loggedIn,
    regionalEnv,
    region,
    changeRegion,
  ]);

  async function createTemplateWorkspace(
    workspaceName = defaultWorkspaceName,
    templateId = defaultTemplateId
  ) {
    const {
      data: { documentCopy: document },
    } = await documentCopy({
      variables: {
        fromId: templateId,
        name: workspaceName,
        projectId: defaultProjectId,
      },
      onCompleted: () => setIsOpen(true),
    });
    return document.id;
  }

  async function handleLoginAsFirstTimeUser() {
    await handleLogin().then(() => {
      if (tabContext !== TEAMS_WINDOW_CONTEXT.tab.personal) {
        telemetry.track("signin.click");
        history.push(state?.from);
        return Promise.resolve();
      }
      return createTemplateWorkspace().then((workspaceId) => {
        telemetry.track("tutorial.begin");
        history.push({
          pathname: `/app/${workspaceId}`,
          state: {
            starter: false,
            dashboardPath: state?.from,
          },
        });
        return Promise.resolve();
      });
    });
  }

  function handleLoginFailure(error) {
    if (error.errorCode === 403) {
      return askConsent();
    }
    if (error.errorCode === 409) {
      setIsOpen(true);
    }
    if (error.errorCode === 401) {
      show(
        <ErrorNotification
          error={{
            title: "Failed to Authenticate",
            message: error.message,
          }}
        />,
        "error"
      );
    } else if (error.errorCode === 400) {
      show(
        <ErrorNotification
          error={{ title: "Unable to Login", message: error.message }}
        />,
        "warn"
      );
    } else if (error.errorCode === 500) {
      show(
        <ErrorNotification
          error={{ title: "Unexpected Error", message: error.message }}
        />,
        "error"
      );
    }
    telemetry.track("signin.fail", {
      value1Type: "Fail Reason",
      value1: error.message,
      orgId: teamsContext.user.tenant.id,
    });
  }

  async function handleLogin() {
    telemetry.track("signin.click");
    if (tabContext === TEAMS_WINDOW_CONTEXT.tab.meeting) {
      gotoDeepLink({ subEntityId: teamsContext.meeting.id });
      return;
    }
    try {
      setShowSpinner(true);
      console.log("getting token...");
      const teamsToken = await getClientToken();
      console.log("initiate login..");
      await login(
        teamsContext.user.tenant.id,
        teamsContext.user.userPrincipalName,
        teamsToken
      )
        .catch((error) => {
          handleLoginFailure(error);
          return Promise.reject(`Failed to Login: ${error.message}`);
        })
        .then((userInfo) => {
          telemetry.identify(teamsContext.user.id, {
            value1Type: "Plan ID",
            value1: userInfo.organization.id,
            value2Type: "Target",
            value2: userInfo.id,
          });
          telemetry.track("signin.success", {
            user_type:
              userState.type === "first-time"
                ? "new-teams | new-creately"
                : userState.msTeamsEnabled
                ? "current-teams | current-creately"
                : "new-teams | current-creately",
          });
          return Promise.resolve(userInfo);
        });
    } catch (error) {
      console.log("uh oh!");
      console.log(error);
      if (error === "invalid_grant") {
        const teamsToken = await requestConsent(
          teamsContext.user.tenant.id
        ).then(getClientToken);
        await login(
          teamsContext.user.tenant.id,
          teamsContext.user.userPrincipalName,
          teamsToken
        )
          .catch((error) => {
            if (error.errorCode === 403) {
              return askConsent();
            } else if (error.errorCode === 409) {
              setIsOpen(true);
            }
            telemetry.track("signin.fail", {
              value1Type: "Fail Reason",
              value1: error.message,
              orgId: teamsContext.user.tenant.id,
            });
            return Promise.reject(`Failed to Login: ${error.message}`);
          })
          .then((userInfo) => {
            telemetry.identify(teamsContext.user.id, {
              value1Type: "Plan ID",
              value1: userInfo.organization.id,
              value2Type: "Target",
              value2: userInfo.id,
            });
            telemetry.track("signin.success", {
              user_type:
                userState.type === "first-time"
                  ? "new-teams | new-creately"
                  : userState.msTeamsEnabled
                  ? "current-teams | current-creately"
                  : "new-teams | current-creately",
            });
            return Promise.resolve(userInfo);
          });
      }
    }
  }

  function gotoDeepLink(
    context,
    entityId = "com.cinergix.creately.personal",
    webUrl = "https://cgx-msteams.ngrok.io"
  ) {
    var encodedWebUrl = encodeURI(webUrl);
    var encodedContext = encodeURI(JSON.stringify(context));
    var taskItemUrl = `https://teams.microsoft.com/l/entity/5350df88-593e-4aa6-a05c-5675edf4177e/${entityId}/?webUrl=${encodedWebUrl}&context=${encodedContext}`;

    microsoftTeams.app.openLink(taskItemUrl);
  }

  function askConsent() {
    const scopes = ["https://graph.microsoft.com/User.Read"];
    return microsoftTeams.authentication.authenticate({
      url:
        window.location.origin +
        `/auth-start.html?clientId=${
          process.env.REACT_APP_AZURE_CLIENT_ID
        }&scope=${encodeURIComponent(scopes.join(" "))}`,
      width: 600,
      height: 535,
    });
  }

  if (isInitializing) {
    return <h1>Loading App...</h1>;
  } else if (tabContext === TEAMS_WINDOW_CONTEXT.meeting.sidepanel) {
    return (
      <div className="flex flex-col space-y-6 sm:space-y-10 items-center px-2 sm:px-0 sm:w-4/5 xl:w-2/3 min-h-screen mx-auto pt-8">
        <h2 className="text-center dark:text-white text-xs xs:text-sm font-semibold dark:font-normal tracking-wide pt-2 text-creately-neutrals">
          Hello, Welcome to Creately!
        </h2>
        <p className="max-w-xl text-center text-creately-primary-xdark dark:text-gray-400 dark:font-normal tracking-wide pt-1">
          Creately helps you run better meetings, improve collaboration and have
          a single source of truth across all your projects.
        </p>
        <div className="xs:hidden">
          <img
            className="mb-2"
            src="/images/meeting-img.png"
            alt="Add a workspace to the meeting stage"
          />
          <div className="text-white bg-gray-900 p-3 rounded">
            <h2 className="text-md">Pro Tip :</h2>
            <div className="text-xs mt-1">
              <span>
                You can add a workspace to the meeting and collaborate with the
                team in real time.
              </span>
              <div className="inline-flex mt-1">
                <span>Click on the</span>
                <span className="mx-2">
                  <svg
                    width="18"
                    height="18"
                    viewBox="0 0 18 18"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M0.75 3.25C0.75 1.91853 1.64156 0.75 2.84091 0.75H15.5227C16.7221 0.75 17.6136 1.91853 17.6136 3.25V14.75C17.6136 16.0815 16.7221 17.25 15.5227 17.25H2.84091C1.64157 17.25 0.75 16.0815 0.75 14.75V3.25ZM2.84091 1.25C2.00684 1.25 1.25 2.09618 1.25 3.25V14.75C1.25 15.9038 2.00684 16.75 2.84091 16.75H15.5227C16.3568 16.75 17.1136 15.9038 17.1136 14.75V3.25C17.1136 2.09618 16.3568 1.25 15.5227 1.25H2.84091ZM9.18171 4.49548C9.10327 4.49552 9.01507 4.53329 8.9412 4.6237L6.28212 7.87822C6.1179 8.07921 6.11814 8.42165 6.2825 8.62226C6.35906 8.7157 6.44726 8.75006 6.52294 8.75C6.59863 8.74994 6.68684 8.71541 6.76334 8.62178L8.81818 6.10682V13.2528C8.81818 13.5782 9.02555 13.7528 9.18181 13.7528C9.33808 13.7528 9.54545 13.5782 9.54545 13.2528V6.10579L11.6042 8.62202C11.6807 8.71555 11.7689 8.75 11.8446 8.75C11.9203 8.75 12.0085 8.71555 12.085 8.62202C12.2493 8.42122 12.2493 8.07878 12.085 7.87798L9.42223 4.62346C9.34833 4.53315 9.26013 4.49545 9.18171 4.49548ZM8.55401 4.30734C8.71024 4.11612 8.93429 3.99559 9.18149 3.99548C9.4287 3.99538 9.65284 4.11573 9.80921 4.30684L12.472 7.56136C12.787 7.94635 12.787 8.55366 12.472 8.93864C12.3089 9.138 12.083 9.25 11.8446 9.25C11.6062 9.25 11.3803 9.138 11.2172 8.93864L10.0454 7.5065V13.2528C10.0454 13.7559 9.70335 14.2528 9.18181 14.2528C8.66027 14.2528 8.31818 13.7559 8.31818 13.2528V7.50903L7.15053 8.93814C6.98756 9.13761 6.76176 9.2498 6.52337 9.25C6.28496 9.2502 6.05899 9.13839 5.89574 8.93914C5.58049 8.55437 5.58019 7.94706 5.89492 7.56186L8.55401 4.30734Z"
                      fill="#C8C6C4"
                    />
                  </svg>
                </span>
                <span> icon to share the</span>
              </div>
              <span className="ml-1">workspaces.</span>
            </div>
          </div>
        </div>
        {userState.type === "none" && (
          <span className="inline-block w-4 h-4 border-2 border-creately-theme-skin border-t-creately-primary-dark spinner"></span>
        )}
        {userState.type === "first-time" && (
          <button
            className="text-xs xs:text-sm space-x-3 font-semibold tracking-wide btn-primary"
            onClick={handleLoginAsFirstTimeUser}
          >
            {showSpinner && (
              <span className="inline-block w-4 h-4 border-2 border-creately-theme-skin border-t-creately-primary-dark spinner"></span>
            )}
            <span>Open Creately</span>
          </button>
        )}
        {userState.type === "existing" && (
          <button
            className="text-xs xs:text-sm space-x-3 font-semibold tracking-wide btn-primary"
            onClick={() => {
              handleLogin().then(() => history.push(state?.from));
            }}
          >
            {showSpinner && (
              <span className="inline-block w-4 h-4 border-2 border-creately-theme-skin border-t-creately-primary-dark spinner"></span>
            )}
            <span>Open Workspaces</span>
          </button>
        )}
      </div>
    );
  }

  return (
    <>
      <MessageModal
        title="Action not Permitted!"
        buttonText="Create Workspace"
        visible={isOpen}
        onClose={() => setIsOpen(false)}
      >
        You need to have a Team plan to use Creately inside Microsoft Teams
      </MessageModal>
      <Layout bgClass="bg-white pt-8 px-2">
        <div className="max-w-[1120px] mx-auto min-h-screen lg:w-5/6">
          <div className="flex flex-col items-center space-y-4 lg:mt-8">
            <div className="w-36 lg:w-auto">
              <img src="./full-logo.png" alt="Creately Logo" />
            </div>
            <p className="text-creately-primary-xdark text-center font-medium text-base lg:text-xl">
              Level up your whiteboarding, diagramming and team collaboration.
            </p>
            <div className="flex items-center flex-col lg:flex-row">
              <div className="flex items-center px-4 space-x-2">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="w-6 h-6 text-creately-semantics-red-light"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="m21 7.5-2.25-1.313M21 7.5v2.25m0-2.25-2.25 1.313M3 7.5l2.25-1.313M3 7.5l2.25 1.313M3 7.5v2.25m9 3 2.25-1.313M12 12.75l-2.25-1.313M12 12.75V15m0 6.75 2.25-1.313M12 21.75V19.5m0 2.25-2.25-1.313m0-16.875L12 2.25l2.25 1.313M21 14.25v2.25l-2.25 1.313m-13.5 0L3 16.5v-2.25"
                  />
                </svg>

                <p className="text-creately-neutrals text-sm lg:text-base">
                  Infinite whiteboard to visualize ideas
                </p>
              </div>
              <div className="flex items-center px-4 space-x-2 border-creately-neutrals-dark lg:border-l-2">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="w-6 h-6 text-creately-semantics-yellow"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M3.375 19.5h17.25m-17.25 0a1.125 1.125 0 0 1-1.125-1.125M3.375 19.5h7.5c.621 0 1.125-.504 1.125-1.125m-9.75 0V5.625m0 12.75v-1.5c0-.621.504-1.125 1.125-1.125m18.375 2.625V5.625m0 12.75c0 .621-.504 1.125-1.125 1.125m1.125-1.125v-1.5c0-.621-.504-1.125-1.125-1.125m0 3.75h-7.5A1.125 1.125 0 0 1 12 18.375m9.75-12.75c0-.621-.504-1.125-1.125-1.125H3.375c-.621 0-1.125.504-1.125 1.125m19.5 0v1.5c0 .621-.504 1.125-1.125 1.125M2.25 5.625v1.5c0 .621.504 1.125 1.125 1.125m0 0h17.25m-17.25 0h7.5c.621 0 1.125.504 1.125 1.125M3.375 8.25c-.621 0-1.125.504-1.125 1.125v1.5c0 .621.504 1.125 1.125 1.125m17.25-3.75h-7.5c-.621 0-1.125.504-1.125 1.125m8.625-1.125c.621 0 1.125.504 1.125 1.125v1.5c0 .621-.504 1.125-1.125 1.125m-17.25 0h7.5m-7.5 0c-.621 0-1.125.504-1.125 1.125v1.5c0 .621.504 1.125 1.125 1.125M12 10.875v-1.5m0 1.5c0 .621-.504 1.125-1.125 1.125M12 10.875c0 .621.504 1.125 1.125 1.125m-2.25 0c.621 0 1.125.504 1.125 1.125M13.125 12h7.5m-7.5 0c-.621 0-1.125.504-1.125 1.125M20.625 12c.621 0 1.125.504 1.125 1.125v1.5c0 .621-.504 1.125-1.125 1.125m-17.25 0h7.5M12 14.625v-1.5m0 1.5c0 .621-.504 1.125-1.125 1.125M12 14.625c0 .621.504 1.125 1.125 1.125m-2.25 0c.621 0 1.125.504 1.125 1.125m0 1.5v-1.5m0 0c0-.621.504-1.125 1.125-1.125m0 0h7.5"
                  />
                </svg>
                <p className="text-creately-neutrals text-sm lg:text-base">
                  1000+ Templates
                </p>
              </div>
              <div className="flex items-center px-4 space-x-2 border-creately-neutrals-dark lg:border-l-2">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="w-6 h-6 text-creately-semantics-purple"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M7.217 10.907a2.25 2.25 0 1 0 0 2.186m0-2.186c.18.324.283.696.283 1.093s-.103.77-.283 1.093m0-2.186 9.566-5.314m-9.566 7.5 9.566 5.314m0 0a2.25 2.25 0 1 0 3.935 2.186 2.25 2.25 0 0 0-3.935-2.186Zm0-12.814a2.25 2.25 0 1 0 3.933-2.185 2.25 2.25 0 0 0-3.933 2.185Z"
                  />
                </svg>

                <p className="text-creately-neutrals text-sm lg:text-base">
                  Easy sharing and collaboration
                </p>
              </div>
            </div>
          </div>

          <div className="flex items-center justify-center mt-4 lg:mt-10">
            {userState.type === "first-time" && !userState.msTeamsEnabled && (
              <div className="flex items-center space-x-3 lg:w-[520px]">
                <div className="flex-grow">
                  <RegionPicker onChange={changeRegion} />
                </div>
                <button
                  onClick={() => {
                    handleLogin().then(() => history.push(state?.from));
                  }}
                  className="text-sm flex items-center space-x-2 rounded bg-creately-primary-dark text-white tracking-wide px-4 md:px-3 py-2 shadow-md hover:bg-creately-primary-dark hover:bg-opacity-90"
                >
                  {showSpinner && (
                    <>
                      <span className="inline-block w-4 h-4 border-2 border-creately-theme-skin border-t-creately-primary-dark spinner"></span>
                      <span>Continue</span>
                    </>
                  )}
                  {!showSpinner && <span>Continue</span>}
                </button>
              </div>
            )}

            {userState.type === "existing" && (
              <button
                onClick={() => {
                  handleLogin().then(() => history.push(state?.from));
                }}
                className="text-sm flex items-center space-x-2 rounded bg-creately-primary-dark text-white tracking-wide px-4 md:px-3 py-2 shadow-md hover:bg-creately-primary-dark hover:bg-opacity-90"
              >
                {showSpinner && (
                  <>
                    <span className="inline-block w-4 h-4 border-2 border-creately-theme-skin border-t-creately-primary-dark spinner"></span>
                    <span>Getting Started</span>
                  </>
                )}
                {!showSpinner && <span>Get Started</span>}
              </button>
            )}

            {(tabContext === TEAMS_WINDOW_CONTEXT.tab.personal ||
              tabContext === TEAMS_WINDOW_CONTEXT.tab.channel ||
              tabContext === TEAMS_WINDOW_CONTEXT.tab.stageView) &&
              userState.type === "first-time" && userState.msTeamsEnabled && (
                <button
                  onClick={() => {
                    handleLogin().then(() => history.push(state?.from));
                  }}
                  className="text-sm flex items-center space-x-2 rounded bg-creately-primary-dark text-white tracking-wide px-4 md:px-3 py-2 shadow-md hover:bg-creately-primary-dark hover:bg-opacity-90"
                >
                  {showSpinner && (
                    <>
                      <span className="inline-block w-4 h-4 border-2 border-creately-theme-skin border-t-creately-primary-dark spinner"></span>
                      <span>Getting Started</span>
                    </>
                  )}
                  {!showSpinner && <span>Get Started</span>}
                </button>
              )}
          </div>

          <div className="mt-4 lg:mt-0">
            <img
              src="./images/login-banner.png"
              alt="Creately helps you run better meetings, collaborate, brainstorm and organize projects visually!"
            />
          </div>
        </div>
      </Layout>
    </>
  );
}
